<template>
  <!-- <v-row v-if="isLoading" class="justify-center mt-2">
    <v-progress-circular color="primary" indeterminate />
  </v-row> -->
  <Detail @onLoading="handleLoading" />
</template>

<script>
import Detail from '../../components/Labs/Detail';

export default {
  components: {
    Detail
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    handleLoading(value) {
      this.isLoading = value;
      console.log(this.isLoading);
    }
  }
};
</script>

<style></style>
