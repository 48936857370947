<template>
  <v-container>
    <div>
      <h1>Follicular Ultrasound</h1>
      <p>Lab Name: {{ data.labName }}</p>
      <p>Test Date: {{ data.testDate }}</p>
      <p>Facility: {{ data.facility }}</p>
      <p>Provider: {{ data.provider }}</p>
    </div>
    <v-card class="rounded-xl">
      <v-card-text>
        <h3>Left Ovary</h3>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <span class="text--primary">Measured Follicles:</span>
                {{ data.leftValues }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <h3>Right Ovary</h3>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <span class="text--primary">Measured Follicles:</span>
                {{ data.rightValues }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <h3>General Observations</h3>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <span class="text--primary">Endometrial Thickness:</span>
                {{ data.endometrialThickness }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment';
require('moment-timezone');
import { api, auth } from '../../sharedPlugin';

export default {
  data() {
    return {
      itemDetail: [],
      data: {}
    };
  },
  async mounted() {
    await this.getItemDetail();
    this.data = this.formatData();
  },
  computed: {
    ...auth.mapComputed(['user'])
  },
  methods: {
    async getItemDetail() {
      await api.Labs.getUSReportFollicular(
        this.user.patientEhrId,
        this.$route.params.id,
        this.user.clinicId
      )
        .then((data) => {
          this.itemDetail = data.map((item) => {
            return {
              Patient_ID: item.Patient_ID,
              Ptn_UltraSound_TestResultsId: item.Ptn_UltraSound_TestResultsId,
              Result_Value: item.Result_Value,
              UOM: item.UOM,
              Element_ShortName: item.Element_ShortName,
              Ovary_Position: item.Ovary_Position,
              Test_Date: item.Test_Date,
              Order_Reference_Text: item.Order_Reference_Text,
              Prefix: item.Prefix,
              Provider_firstname: item.Provider_firstname,
              Provider_Lastname: item.Provider_Lastname,
              Suffix: item.Suffix
            };
          });
        })
        .catch((error) => {
          // Handle the error by throwing an error message
          console.log(
            'Error with getting UltraSound Follicualar Report information: '
          );
          console.log(error);
        })
        .finally(() => {
          // this.$emit('onLoading', false);
        });
      await api.Labs.getUSReportEndo(
        this.user.patientEhrId,
        this.$route.params.id,
        this.user.clinicId
      )
        .then((data) => {
          data.map((item) => {
            if (item.Summary_Element == 'Endo Thickness') {
              this.itemDetail.endometrialThickness = item.Ptn_TestSum_Value;
            }
            this.itemDetail.facility = item.Facility_Name;
          });
        })
        .catch((error) => {
          // Handle the error by throwing an error message
          console.log(
            'Error with getting UltraSound Endometrial Report information: '
          );
          console.log(error);
        })
        .finally(() => {
          this.$emit('onLoading', false);
        });
    },
    formatData() {
      const restructuredObject = {
        labName: [],
        testDate: [],
        facility: [],
        provider: [],
        leftValues: [],
        rightValues: [],
        endometrialThickness: []
      };

      const finalObject = {};

      this.itemDetail.forEach((element) => {
        const providerName = [];
        switch (element.Ovary_Position) {
          case 'L':
            if (element.Result_Value + element.UOM !== 'mm') {
              restructuredObject.leftValues.push(
                element.Result_Value + element.UOM
              );
            }

            break;
          case 'R':
            if (element.Result_Value + element.UOM !== 'mm') {
              restructuredObject.rightValues.push(
                element.Result_Value + element.UOM
              );
            }
            break;
          default:
            break;
        }

        providerName.push(element.Prefix ?? '');
        providerName.push(element.Provider_firstname ?? '');
        providerName.push(element.Provider_Lastname ?? '');
        providerName.push(element.Suffix ?? '');
        restructuredObject.provider.push(providerName.join(' '));

        var timeZoneAbbr = moment.tz(moment(), moment.tz.guess()).format('z');
        var abbr = '(' + timeZoneAbbr + ')';
        restructuredObject.testDate.push(
          moment(element.Test_Date).format('MMM DD, YYYY h:mma ') + abbr
        );
        restructuredObject.labName.push(element.Order_Reference_Text);
      });

      // Join arrays and remove duplicates for labName, testDate, facility, and provider
      finalObject.labName = [...new Set(restructuredObject.labName)].join(', ');
      finalObject.testDate = [...new Set(restructuredObject.testDate)].join(
        ', '
      );
      finalObject.facility = [...new Set(restructuredObject.facility)].join(
        ', '
      );
      finalObject.provider = [...new Set(restructuredObject.provider)].join(
        ', '
      );

      // Join arrays for leftValues and rightValues without removing duplicates
      finalObject.leftValues = restructuredObject.leftValues.join(', ');
      finalObject.rightValues = restructuredObject.rightValues.join(', ');

      // Handle the endometrial ultrasound information
      finalObject.endometrialThickness = this.itemDetail.endometrialThickness;

      // Handle the facility information
      finalObject.facility = this.itemDetail.facility;

      return finalObject;
    }
  }
};
</script>

<style></style>
